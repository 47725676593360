import React from "react"
import { Link } from "gatsby"
import '../components/blog_unique.css';
import { Row,Col } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import HU from '../components/hu-HU_lang.js';
import EN from '../components/en-EN_lang.js'; 
import Layout from "../components/layout";
const ReactMarkdown = require('react-markdown')


export default function BlogTemplate({ pageContext: { title, id, content, imgUrl, create_date, description, postData } }) {
  if (typeof window !== 'undefined'){
    var pageScrolling = false
    window.scrollTo(0, 0);
    document.addEventListener('scroll', () => {
      const scrolling = window.scrollY < 100;
      if (scrolling !== pageScrolling) {
          var pageScrolling = scrolling
      }
    });
  }else{
    var pageScrolling = false
  }

  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "hu-HU";
    }
  
    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";
  
    switch (lang) {
      case "hu":
        return "hu-HU";
      default:
        return "en";
    }
  };
  //console.log(this.state.scrolling);
  var userLang = getRedirectLanguage();
  return (
    <Layout>
      <div className={pageScrolling ? "otherPageDiv" : "otherPageDivScrolling"}>
      <div className="titleDiv">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:type" content="website" />
          <meta property="og:description " content={description} />
          <meta property="og:url" content={"https://automeasify.com/post/"+id} />
          <meta property="og:image" content={"https://blingdy.app"+imgUrl} />
      </Helmet>
      </div>
      <div className="contentDivFylerBlog">
        <img src={"https://blingdy.app"+imgUrl}></img>
        <Row className="headRowBlogPost">
            <Col xl={10} md={10} sm={8}>
                <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.blogAuthor : EN.blogAuthor}: Stefel Viktor</h6>
            </Col>
            <Col xl={2} md={2} sm={4}>
                <p>{create_date}</p>
            </Col>
        </Row>
        <h2>{title}</h2>
        <div className="contentDivBlog">
        <ReactMarkdown source={content} />
        </div>
        <button className="backToAllBlog"><Link to="/blog">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.button5 : EN.button5}</Link></button>        
      </div>
  </div>
  </Layout>
  )
}